const constants = {
  PAGE_TITLE: 'GROA - Mer',
  campaignImages: [
    'https://portal.ee-mobility.com/images/campaign/campaign_side2.jpg',
    'https://portal.ee-mobility.com/images/campaign/campaign_side4.jpg',
    'https://portal.ee-mobility.com/images/campaign/campaign_side5.jpg',
  ],
  roles: {
    ROLE_DRIVER: 'ROLE_DRIVER',
    ROLE_FLEET_MANAGER: 'ROLE_FLEET_MANAGER',
    ROLE_GROA_USER: 'ROLE_GROA_USER',
  },
  assumedRoles: {
    DRIVER: 'driver',
    MANAGER: 'manager',
    USER: 'user',
  },
  vehicleTypes: {
    BEV: 'BEV',
    PHEV: 'PHEV',
  },
  vehicleModel: {
    OTHER: 'Sonstige',
  },
  carOwnership: {
    COMPANY: 'COMPANY',
    PRIVATE: 'PRIVATE',
  },
  countries: ['AT', 'BE', 'CH', 'DE', 'FR', 'NL'],
  statisticsPeriods: {
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
  },
  contractStatus: {
    INACTIVE: 'INACTIVE',
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
  },
  accessCategories: {
    BUSINESS: 'BUSINESS',
    PUBLIC: 'PUBLIC',
    HOME: 'HOME',
  },
  languages: {
    EN: 'en',
    DE: 'de',
  },
  faqTopicsQuestionsCount: {
    // number represents the number of questions under this topic
    contract: { configurator: 1, start: 7, duration: 2, end: 3 },
    charging: { statistics: 1, places: 2, blocking: 3 },
    homestation: { installation: 9, usage: 6 },
    groa: { account: 2, general: 1 },
  },
  faqEntryDate: '03.07.2024',
  leasingPeriod: [24, 36, 48, 60],
  annualMileageOptions: [10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000],
  monthsArray: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  daysOfWeekShort: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
} as const;

export default constants;
